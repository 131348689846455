import React, { useState } from "react"
import { Link } from "gatsby"
import { addItemToOrder } from "api/db"

const ProductCell = ({ product, uid, orderInProgress, activeOrder }) => {
  let itemCode = product.itemCode
  let path = itemCode.replace(/\s+/g, "-").toLowerCase()
  let price = (Math.round(product.price * 100) / 100).toFixed(2)

  const [cartClicked, setCartClicked] = useState(false)

  let quantityOrdered = 0

  if (orderInProgress) {
    Object.values(activeOrder).map((product) => {
      if (itemCode == product.itemCode) {
        quantityOrdered = product.cases
      }
    })
  }

  return (
    <Link
      to={`/products/${path}`}
      className="flex-1 has-text-dark card card-tti"
      key={product.description}
      state={{ product }}
    >
      <div>
        <div>
          <h3 className="has-text-link has-text-weight-bold">
            {product.itemCode}
          </h3>
          <h1 className="has-text-weight-bold	product-description">
            {product.description}{" "}
            {product.stock === "yes" ? (
              <span className="tag is-success is-rounded is-stock has-text-weight-bold is-small">
                {" "}
                In Stock{" "}
              </span>
            ) : (
              <span className="tag is-danger is-rounded is-stock has-text-weight-bold is-small">
                {" "}
                Out of Stock{" "}
              </span>
            )}
          </h1>
        </div>
        <figure className="product-image">
          <img src={`/products/${path}.png`} />
        </figure>
        <h1 className="has-text-weight-bold	quantity">
          {quantityOrdered > 0 ? (
            <p className="tag is-success is-rounded">
              {quantityOrdered > 1 ? (
                <span>{quantityOrdered} Cases Added</span>
              ) : (
                <span>{quantityOrdered} Case Added</span>
              )}
            </p>
          ) : null}
        </h1>
        <h6 className="has-text-left is-inline packing has-text-weight-bold">
          {product.packing}/case
        </h6>
        <span
          pattern="\d*"
          className="cart-container"
          min="1"
          placeholder="0"
          onClick={(e) => {
            e.preventDefault()
            if (uid) {
              addItemToOrder(
                uid,
                path,
                product.itemCode,
                product.description,
                product.price,
                Math.floor(product.packing),
                Math.floor(1)
              )
              setCartClicked(true)
            } else {
              alert("You must create an account or login to place an order.")
            }
          }}
        >
          {cartClicked ? (
            <button className="button is-info is-light is-small is-rounded">
              &#10004;{" "}
            </button>
          ) : (
            <button className="button is-info is-light is-small is-rounded">
              Add to cart
            </button>
          )}
        </span>
        <h6 className="has-text-right is-inline price">${price}</h6>
      </div>
    </Link>
  )
}

export default ProductCell
