import React from "react"

import { Link } from "gatsby"
import Search from "./Search"

import AuthUserContext from "./Session/AuthUserContext"

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser => (authUser ? <NavigationAuth /> : <NavigationNonAuth />)}
  </AuthUserContext.Consumer>
)

const NavigationAuth = () => (
  <div>
    <nav
      className="navbar site-nav"
      role="navigation"
      aria-label="main navigation"
    >
      <Link className="navbar-item has-text-weight-bold" to="/">
        <p> Home </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/order">
        <p> Cart </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/account">
        <p> Account </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/contact">
        <p> Contact </p>
      </Link>
      {JSON.parse(localStorage.getItem("uid")) !==
      "HvIOG2fJjkSmLe9MCIVEecTl8IR2" ? (
        <div></div>
      ) : (
        <>
          <Link className="navbar-item has-text-weight-bold" to="/deploy">
            <p> Deploy </p>
          </Link>
          <Link className="navbar-item has-text-weight-bold" to="/admin">
            <p> Admin </p>
          </Link>
        </>
      )}

      <Search />
    </nav>
  </div>
)

const NavigationNonAuth = () => (
  <div>
    <nav
      className="navbar site-nav"
      role="navigation"
      aria-label="main navigation"
    >
      <Link className="navbar-item has-text-weight-bold" to="/">
        <p> Home </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/contact">
        <p> Contact </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/login">
        <p> Log in </p>
      </Link>
      <Link className="navbar-item has-text-weight-bold" to="/register">
        <p> Create an Account &#8250;</p>
      </Link>
    </nav>

    <Search />
  </div>
)

export default Navigation
