import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "./ticktockinc.css"
import "./bulma.css"

import withAuthentication from "./Session/withAuthentication"

const Layout = ({ children }) => {
  return (
    <div>
      <style>{`@media print {.no-print {display: none !important;}}`}</style>
      <Header />
      <section className="body">
        <main className="page-container">{children}</main>
        <footer className="footer no-print">
          <div className="container">
            <h3 className="is-size-5">
              240 Moonachie Ave. Suite D. Moonachie, NJ
            </h3>

            <h3 className="is-size-5">
              Phone: 201-853-9200 | Fax: 201-853-9477 | Email: tiktok@att.net
            </h3>
            <br />
            <h3 className="is-size-4">
              {" "}
              <strong>
                {" "}
                © {new Date().getFullYear()}, Tick Tock Inc.{" "}
              </strong>{" "}
            </h3>
            <br />
            <small>
               Prices are subject to change and quantities can be limited. Please contact us for more information.{" "}
            </small>
          </div>
        </footer>
      </section>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withAuthentication(Layout)
