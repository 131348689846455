import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import { useStaticQuery, graphql } from "gatsby"

const Search = () => {
  const [query, setQuery] = useState("")

  return (
    <div className="search-container">
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder="What are you looking for?"
            onChange={event => setQuery(event.target.value)}
          />
        </div>
        <div className="control">
          <a href={`/results?query=${query}`} className="button is-info">
            Search
          </a>
        </div>
      </div>
    </div>
  )
}

export default Search
