import { db } from "./firebase"

export const createUser = (
  uid,
  companyName,
  companyAddress,
  companyCity,
  companyState,
  companyZipCode,
  companyTaxID,
  userName,
  userPosition,
  companyPhoneNumber,
  userCellNumber,
  companyFaxNumber,
  natureOfBusiness,
  emailAddress,
  createdAt
) =>
  db
    .ref("users")
    .child(uid)
    .once("value", function(snapshot) {
      var exists = snapshot.val() !== null
      userExistsCallback(
        exists,
        uid,
        companyName,
        companyAddress,
        companyCity,
        companyState,
        companyZipCode,
        companyTaxID,
        userName,
        userPosition,
        companyPhoneNumber,
        userCellNumber,
        companyFaxNumber,
        natureOfBusiness,
        emailAddress,
        createdAt
      )
    })

function userExistsCallback(
  exists,
  uid,
  companyName,
  companyAddress,
  companyCity,
  companyState,
  companyZipCode,
  companyTaxID,
  userName,
  userPosition,
  companyPhoneNumber,
  userCellNumber,
  companyFaxNumber,
  natureOfBusiness,
  emailAddress,
  createdAt
) {
  if (exists) {
    alert("User already registered and now is logged in.")
  } else {
    db.ref(`users/${uid}`).set({
      uid,
      companyName,
      companyAddress,
      companyCity,
      companyState,
      companyZipCode,
      companyTaxID,
      userName,
      userPosition,
      companyPhoneNumber,
      userCellNumber,
      companyFaxNumber,
      natureOfBusiness,
      emailAddress,
      createdAt,
    })
  }
}

export const fetchUserDataByUid = uid => db.ref("users").child(uid)

// export const addItemToOrder = (
//   uid,
//   path,
//   itemCode,
//   description,
//   price,
//   packing,
//   cases
// ) => {
//   let pieces = cases * packing
//   let totalPrice = Math.floor(pieces * price)
//   let index = 0
//   db.ref(`orders/${uid}`).once("value", function(snapshot) {
//     if (snapshot.val()) {
//       snapshot.forEach(function(childSnap) {
//         index = index + 1
//         let childData = childSnap.val()
//         console.log(snapshot.val())
//         console.log(snapshot)
//
//         console.log(childData)
//         console.log(Object.keys(snapshot.val()).length)
//         console.log(index)
//
//         if (
//           childData.status === "in-progress" &&
//           Object.keys(snapshot.val()).length === index // we are on the last entry and it is in progress
//         ) {
//           let orderId = childData.orderNumber
//           let date = new Date().toLocaleString()
//           db.ref(`orders/${uid}/${orderId}`).update({
//             status: "in-progress",
//             dateUpdated: date,
//           })
//           db.ref(`orders/${uid}/${orderId}/${path}`).update({
//             itemCode,
//             description,
//             price,
//             packing,
//             cases,
//             pieces,
//             totalPrice,
//           })
//         } else if (Object.keys(snapshot.val()).length === index) {
//           // only if we have checked every entry until the last one do we create a new order in progress
//           // there is no order in-progress, but userid in orders does exist
//           db.ref("counts").once("value", function(snapshot) {
//             let orderId = snapshot.val().orderCount + 1
//             db.ref("counts").update({ orderCount: orderId })
//             let date = new Date().toLocaleString()
//             db.ref(`orders/${uid}/${orderId}`).update({
//               status: "in-progress",
//               dateUpdated: date,
//               orderNumber: orderId,
//               uid: uid,
//             })
//             db.ref(`orders/${uid}/${orderId}/${path}`).update({
//               itemCode,
//               description,
//               price,
//               packing,
//               cases,
//               pieces,
//               totalPrice,
//             })
//           })
//         }
//       })
//     } // uid in orders does not exist
//     else {
//       db.ref("counts").once("value", function(snapshot) {
//         let orderId = snapshot.val().orderCount + 1
//         db.ref("counts").update({ orderCount: orderId })
//         let date = new Date().toLocaleString()
//         db.ref(`orders/${uid}/${orderId}`).update({
//           status: "in-progress",
//           dateUpdated: date,
//           orderNumber: orderId,
//           uid: uid,
//         })
//         db.ref(`orders/${uid}/${orderId}/${path}`).update({
//           itemCode,
//           description,
//           price,
//           packing,
//           cases,
//           pieces,
//           totalPrice,
//         })
//       })
//     }
//   })
// }

export const addItemToOrder = (
  uid,
  path,
  itemCode,
  description,
  price,
  packing,
  cases
) => {
  let pieces = cases * packing
  let piecesTimesPrice = parseFloat(pieces * price)
  let totalPrice = (Math.round(piecesTimesPrice * 100) / 100).toFixed(2)

  db.ref(`orders`)
    .orderByChild("uid")
    .equalTo(uid)
    .limitToLast(1)
    .once("value", function(snapshot) {
      console.log("All orders from user: ")
      console.log(snapshot.val())
      if (snapshot.val()) {
        let lastOrderInListOfCompany = snapshot.val()
        let order =
          lastOrderInListOfCompany[Object.keys(lastOrderInListOfCompany)]
        if (order.status === "in-progress") {
          console.log("added to existing order")
          let orderId = order.orderNumber
          let date = new Date().toLocaleString()
          db.ref(`orders/${orderId}`).update({
            status: "in-progress",
            dateUpdated: date,
          })
          db.ref(`orders/${orderId}/${path}`).update({
            itemCode,
            description,
            price,
            packing,
            cases,
            pieces,
            totalPrice,
          })
        } else {
          console.log(
            "create new order because none of past orders are in progress"
          )
          // create new order because none of past orders are in progress
          db.ref("counts").once("value", function(snapshot) {
            let orderId = snapshot.val().orderCount + 1
            db.ref("counts").update({ orderCount: orderId })
            let date = new Date().toLocaleString()
            db.ref(`orders/${orderId}`).update({
              status: "in-progress",
              dateUpdated: date,
              orderNumber: orderId,
              uid: uid,
            })
            db.ref(`orders/${orderId}/${path}`).update({
              itemCode,
              description,
              price,
              packing,
              cases,
              pieces,
              totalPrice,
            })
          })
        }
      } else {
        console.log("since there is no record of past orders, make a new one")
        // since there is no record of past orders, make a new one
        db.ref("counts").once("value", function(snapshot) {
          let orderId = snapshot.val().orderCount + 1
          db.ref("counts").update({ orderCount: orderId })
          let date = new Date().toLocaleString()
          db.ref(`orders/${orderId}`).update({
            status: "in-progress",
            dateUpdated: date,
            orderNumber: orderId,
            uid: uid,
          })
          db.ref(`orders/${orderId}/${path}`).update({
            itemCode,
            description,
            price,
            packing,
            cases,
            pieces,
            totalPrice,
          })
        })
      }
    })
}

// fix delete item after this!!!! & retrieve item & submit item!!!!

export const deleteItem = (orderNumber, path) => {
  db.ref(`orders/${orderNumber}/${path}`).remove()
}

export const retrieveOrders = uid =>
  // if (typeof window !== "undefined") {
  db
    .ref("orders")
    .orderByChild("uid")
    .equalTo(uid)
    .limitToLast(1)
    .once("value")
// }

export const submitOrder = (orderNumber, totalCases, totalPrice) => {
  db.ref(`orders/${orderNumber}`).once("value", function(snapshot) {
    let data = snapshot.val()
    if (data.status === "in-progress") {
      let orderId = snapshot.key
      let date = new Date().toLocaleString()
      db.ref(`orders/${orderNumber}`).update({
        status: "submitted",
        dateUpdated: date,
        totalCases,
        totalPrice,
      })
    }
  })
}

export const updateNotes = (orderNumber, notes) => {
  db.ref(`orders/${orderNumber}`).update({
    notes: notes,
  })
}

export const numberOf = type => db.ref(`counts/${type}Count`).once("value")

export const retrieveOrderByOrderNumber = orderNumber =>
  db.ref(`orders/${orderNumber}`).once("value")

export const retrieveAllOrders = () => db.ref("orders").once("value")

export const retrieveAllUsers = () => db.ref("users").once("value")
