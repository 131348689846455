import { Link } from "gatsby"
import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import Navigation from "./navigation"

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSpreadsheetSheet1 {
          nodes {
            id
          }
        }
      }
    `
  )

  let products = data.allGoogleSpreadsheetSheet1.nodes
  

  return (
    <header className="no-print">
      <section className="hero is-info is-small">
        <div className="hero-body">
          <div className="container has-text-centered">
            <Link to="/" className="logo">
              <h1 className="is-size-2 has-text-weight-bold	">Tick Tock Inc.</h1>
            </Link> 
            <h2 className="is-size-4">
              Importers & Distributors of General Merchandise
            </h2>
          </div>
        </div>
      </section>
      <div className="notification is-light">
        <h3 className="is-size-5 has-text-centered has-text-weight-bold">
          {" "}
          Distributor of
          <span className="has-text-link">
            {" "}
            {products.length.toLocaleString()}
          </span>
          + retail products at the best prices. Serving our customers since{" "}
          <span className="has-text-link"> 1987</span>.
        </h3>
      </div>
      <Navigation />
    </header>
  )
}

export default Header
